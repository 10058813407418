<template>
    <div class="all-height d-flex justify-center">
      <div class="all-width">
        <v-card class="shadow-off" loader-height="2" :loading="loading" :disabled="loading">
          <v-card-text>
            <div class="">
              <div class="d-flex">
                  <p class="mb-1">Home</p>
                  <p class="px-2 mb-1">/</p>
                  <p class="mb-1">{{ metadata.description }}</p>
              </div>
              <div class="d-flex">
                  <p class="black--text font-weight-bold text-h5">Borrowing Schedule</p>
                  <v-btn class="shadow-off ml-2" small icon
                      @click="refreshData()"><v-icon>mdi-refresh</v-icon></v-btn>
              </div>
          </div>
  
            <div v-if="loading && (!metadata._id || !botdetails._id)">
              <v-skeleton-loader type="table-heading, list-item-two-line, article, article"></v-skeleton-loader>
            </div>
            <div class="py-8" v-else-if="!botdetails._id">
              <div class="body-1 mb-4">
                No Bot details found
              </div>
            </div>
            <div v-else-if="metadata.stage === 3 && Object.keys(rundetails).length === 0" class="pa-8 title text-center grey--text">
              Bot not run
            </div>
            <div v-else>
              <div v-if="metadata.stage !== 3">
                <v-alert v-if="!savedParameters && hasParameters" type="" color="caccounting" text dense border="left">Save the Input parameters to
                  proceed</v-alert>
                <v-alert v-else-if="!savedFiles && hasFiles" type="" color="caccounting" text dense border="left">Upload all the required files to start
                  the analysis</v-alert>
              </div>
              <div v-if="(processedFiles || !hasFiles) && (savedParameters || !hasParameters) && metadata.stage !== 3" class="d-flex">
                <v-spacer></v-spacer>
                <v-btn color="black" v-if="Object.keys(rundetails).length === 0" class="ml-2 shadow-off white--text "  height="4vh" small @click="startRun()">
                  <v-icon class="mr-1">mdi-play</v-icon>
                  Start Run
                </v-btn>
                <v-btn color="black" v-else class="ml-2 shadow-off white--text  " height="4vh" small @click="startRun()">
                  <v-icon class="mr-1">mdi-play</v-icon>
                  Re Run
                </v-btn>
              </div>
              <v-tabs v-model="tab" color="caccounting" height="30">
                <v-tab :key="1" v-if="hasParameters" class="text-transform-none justify-start"><v-icon
                    class="mr-2">mdi-play-box-multiple-outline</v-icon>Input Parameters</v-tab>
                <v-tab :key="2" v-if="savedParameters || (!hasParameters && hasFiles)" class="text-transform-none justify-start"><v-icon
                    class="mr-2">mdi-cloud-upload-outline</v-icon>Files</v-tab>
                <v-tab :key="3" class="text-transform-none justify-start"
                  v-if="(savedParameters || !hasParameters) && Object.keys(rundetails).length > 0"
                  @click="generateAnnexurelist(selectedresulttab)"><v-icon
                    class="mr-2">mdi-format-list-bulleted</v-icon>Result</v-tab>
              </v-tabs>
              <v-divider></v-divider>
              <v-tabs-items v-model="tab">
                <v-tab-item :key="1" v-if="hasParameters"> 
                  
                  <InputParamerter class="mt-4" :parameter="setupList.parameter" :parametervalue="inputparametersvalues" :editable="metadata.stage !== 3"
                    :userType="false" :apiParam="'/v2/bot/analysis/storeinputparameter/' + botdetails._id"
                    @startloading="loading = true" @stoploading="loading = false" @reload="refreshData()" />
           
                </v-tab-item>
                <v-tab-item :key="2">
                  <FileUploads :files="setupList.file"  :filevalues="uploaddetails" :editable="metadata.stage !== 3"
                    :storepath="'/v2/bot/analysis/storefile/' + botdetails._id" :fileproerr="fileprocesserrors"
                    :fileprostage="fileprocessstage" :fileuppercent="fileuploadpercent" :filesdataPros="filesdata"
                    :fileconfig="fileConfigInfo" @startloading="loading = true" @stoploading="loading = false"
                    @reload="refreshData()" :right="right" />
                </v-tab-item>
                <v-tab-item :key="3" v-if="Object.keys(rundetails).length > 0">
                  <div>
                    <div class="d-flex my-2">
                      <div style="min-width: 170px; max-width: 170px">
                        <div v-if="Object.keys(rundetails).length > 1" >
                          <template v-for="(v, k) in verificationlist">
                            <div :key="k" :class="`${selectedresulttab === k
                              ? 'border-left-primary'
                              : 'border-left-transparent'
                              }`">
                              <div @click="selectedresulttab = k; generateAnnexurelist(v);"
                                :class="`px-2 py-1 d-flex align-start cursor-pointer hoverable ${selectedresulttab === k ? 'grey lighten-4' : ''}`">
                                <div class="mr-2">
                                  <v-tooltip bottom content-class="tooltip-bottom"
                                    v-if="(rundetails[v._id] || {}).stage === 1">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon v-bind="attrs" v-on="on" color="success">mdi-check-circle</v-icon>
                                    </template>
                                    <span>Completed</span>
                                  </v-tooltip>
                                  <v-tooltip bottom content-class="tooltip-bottom" v-else-if="
                                    (rundetails[v._id] || {}).stage === 4
                                  ">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon v-bind="attrs" v-on="on" color="error">mdi-radiobox-marked</v-icon>
                                    </template>
                                    <span>Currently running</span>
                                  </v-tooltip>
                                  <v-tooltip bottom content-class="tooltip-bottom" v-else>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon v-bind="attrs" v-on="on" color="info">mdi-timer-sand</v-icon>
                                    </template>
                                    <span>Pending</span>
                                  </v-tooltip>
                                </div>
                                <div>
                                  {{ v.name }}
                                </div>
                              </div>
                            </div>
                          </template>
                        </div>
                        <div v-else>
                          <v-btn color="primary" class="mb-4 shadow-off" small @click="downloadResult()">
                            <v-icon class="mr-1">mdi-file-excel</v-icon>
                            Download Excel
                          </v-btn>                      
                          <div class="" style="max-height:70vh; overflow:scroll">
                            <template v-for="(v, k) in annexurelist">
                              <div :key="k" :class="`${selectedannexure === v.value ? 'border-left-primary': 'border-left-transparent'}`">
                                <div @click="selectedannexure = v.value; readAnnexure(v.value,(verificationlist[selectedresulttab] || {})._id)"
                                  :class="`px-2 py-1 d-flex align-start cursor-pointer hoverable ${selectedannexure === v.value ? 'grey lighten-4' : ''}`">
                                  <div>
                                    {{ v.name }}
                                  </div>
                                </div>
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>
                      <div class="flex-grow-1 px-4" style="min-width: 0">
                        <div class="d-flex">
                          <lb-dropdown v-if="Object.keys(rundetails).length > 1" label="Annexures" v-model="selectedannexure" :items="annexurelist" @change="
                            readAnnexure(
                              selectedannexure,
                              (verificationlist[selectedresulttab] || {})._id
                            )
                          " />
                          <v-spacer class="flex-grow-1"></v-spacer>
                          <!-- <v-btn icon><v-icon>mdi-download</v-icon></v-btn> -->
                        </div>
                        <div class="" v-if="currentannexuredata.length > 0">
                          <v-simple-table dense class="simple-table border-on" :fixed-header="true" :height="tableHeight">
                            <template v-slot:default>
                              <thead>
                                <tr class="">
                                  <template v-for="(v, k) in currentannexuredata[0]">
                                    <th class="grayBackground" :key="k" v-if="k !== '_id'">
                                      {{ (k || "").replaceAll("_", " ") }}
                                    </th>
                                  </template>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(v, k) in currentannexuredata" :key="k">
                                  <template v-for="(vv, kk) in currentannexuredata[0]">
                                    <td :key="kk" v-if="kk !== '_id'">
                                      {{
                                        typeof v[kk] === "number"
                                        ? $nova.formatNumber(v[kk].toFixed(0))
                                        : v[kk]
                                      }}
                                    </td>
                                  </template>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </div>
                        <div v-else class="text-center grey--text title pa-6">
                          No data available
                        </div>
                      </div>
                    </div>
                  </div>
                </v-tab-item>
              </v-tabs-items>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </template>
  
  <script>
    import InputParamerter from '../../../../../views/conserve/InputParamerter.vue';
  import FileUploads from '../../../../../views/conserve/FileUploads.vue';
  export default {
    name: 'financialclose_automation_bots_component',
    // props: ['props'],
    data: function () {
      return {
        right:"financialclose_pbc_project",
        project: "",
        loading: false,
        id: "",
        tab: 0,
        metadata: {},
        botproject: {},
        botdetails: {},
        setupList: {},
        verificationlist: [],
        hasParameters: false,
        savedParameters: false,
        inputparametersvalues: {},
        uploaddetails: {},
        fileuploaddata: {},
        fileprocesserrors: {},
        fileuploadpercent: {},
        fileprocessstage: {},
        filesdata: {},
        processedFiles: false,
        savedFiles: false,
        hasFiles: false,
        rundetails: {},
        annexurelist: [],
        selectedresulttab: 0,
        selectedannexure: "",
        currentannexuredata: [],
        annexuredata: {},
        fileConfigInfo: {},
        tableHeight:''
      }
    },
    props: {
      botid: {
        type: String,
        default: ""
      },
    },
    components: {
     FileUploads ,InputParamerter
    },
    created() {
      this.refreshData();
      this.tableHeight = window.innerHeight - 250;
    },
    activated() {
      this.refreshData();
    },
    methods: {
      refreshData() {
        this.id = this.$route.params.id || "";
        this.userid = this.$store.state.user.id;
        this.currentannexuredata = [];
        this.annexuredata = {};
        this.getData();
      },
      getData() {
        this.loading = true;
        this.project = this.$route.params.id;
  
        return this.axios.post("/v2/financialclose/analysis/getbotdetails/" + this.id).then(dt => {
          if (dt.data.status === "success" && dt.data.data.length > 0) {
            let data = dt.data.data[0] || {}
            this.metadata = data.metadata || {};
            this.userlist = [];
            this.userlist = this.metadata.userlist || [];
            this.managerlist = [];
            this.managerlist = this.metadata.managerlist || [];
            this.stage = this.metadata.stage;
          }
        }).catch(err => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
        }).finally(() => {
          this.loading = false;
          this.loadBot();
        })
      },
      generateAnnexurelist: function (index = 0) {
        let ndata = [];
        let selectedverification = this.verificationlist[index] || {};
        let rundata = this.rundetails[selectedverification._id] || {};
        console.log(selectedverification, rundata);
        if (Object.keys(rundata).length > 0) {
          let annxdata = rundata;
          let hasannexure = false;
          console.log(annxdata);
          for (let i = 0; i < (annxdata.annexure || []).length; i++) {
            const el = (annxdata.annexure || [])[i];
            console.log(el);
            ndata.push({ name: el.name, value: el.collection });
            if (el.collection === this.selectedannexure) hasannexure = true;
          }
          if (!hasannexure) this.selectedannexure = ndata[0]?.value;
          this.readAnnexure(this.selectedannexure, selectedverification._id);
        }
        this.annexurelist = ndata;
      },
      readAnnexure(c, v) {
        if(c){
          // if (this.annexuredata[c]) {
          //   this.currentannexuredata = this.annexuredata[c];
          // } else {
            this.loading = true;
            Promise.resolve()
              .then(() => {
                return this.axios.post("/v2/bot/analysis/readannexure/" + this.botdetails._id + "/c/" + c + "/v/" + v);
              })
              .then((dt) => {
                if (dt.data.status === "success") {
                  this.annexuredata[c] = dt.data.data;
                  this.currentannexuredata = this.annexuredata[c];
                } else throw new Error(dt.data.message || "Error reading annexure");
              })
              .catch((err) => {
                this.$store.commit("sbError", err.message || err || "Unknown error!");
                this.currentannexuredata = [];
                console.log(err);
              })
              .finally(() => {
                this.loading = false;
              });
          // }
        }
      },
      loadBot() {
        this.loading = true;
        return this.axios.post("/v2/financialclose/analysis/loadbotdetails/" + this.id + "/b/" + this.botid).then(dt => {
          if (dt.data.status === "success" && dt.data.data.length > 0) {
            let data = dt.data.data[0] || {};
            this.botdetails = data.metadata || {};
            this.uploaddetails = data.uploaddetails || {};
            this.setupList = data.setup || {};
            this.verificationlist = this.setupList.verification || {};
            this.inputparametersvalues = this.botdetails.inputparameter || {};
            this.fileConfigInfo = this.setupList?.fileconfig || {};
            this.rundetails = {};
            for (const i of data.rundetails || []) {
              this.rundetails[i.verification] = i;
            }
            this.generateAnnexurelist();
  
            // to check if inputparameter are available and if the same is saved
            this.savedParameters = false;
            if ((this.setupList.parameter || []).length > 0) {
              this.hasParameters = true;
              let allparametersaved = true;
              for (let ele of this.setupList.parameter) {
                if (!Object.hasOwnProperty.call((this.botdetails.inputparameter || {}), ele._id)) {
                  allparametersaved = false;
                }
              }
              this.savedParameters = allparametersaved;
            } else {
              this.hasParameters = false;
            }
  
            // set default input parameter values
            let availableids = [];
            for (const k in this.inputparametersvalues) {
              if (Object.hasOwnProperty.call(this.inputparametersvalues, k)) {
                availableids.push(k);
              }
            }
            for (const i of this.setupList.parameter || []) {
              if (availableids.indexOf(i._id) === -1) {
                if (
                  Object.hasOwnProperty.call(
                    this.setupList.inputparameterconfig || {},
                    i._id
                  )
                )
                  this.inputparametersvalues[i._id] = (
                    this.setupList.inputparameterconfig[i._id] || {}
                  ).value;
                else this.inputparametersvalues[i._id] = i?.value;
              }
            }
  
            // to check if files are available and if the same is saved and also flag processing errors
            if ((this.setupList.file || []).length > 0) {
              this.hasFiles = true;
              let allfileprocessed = true;
              let allfilesaved = true;
              let availablefiles = [];
              for (const i of this.botdetails.files || []) {
                availablefiles.push(i.fileid);
              }
              for (let ele of this.setupList.file) {
                let id = ele._id;
                if ((this.uploaddetails[id] || {}).processed !== 1) {
                  allfileprocessed = false;
                }
                if ((this.uploaddetails[id] || {}).processed === -1) this.fileprocesserrors[id] = "Error while processing file";
                if (availablefiles.indexOf(id) === -1 || (this.uploaddetails[id].processed || 0) === -1) allfilesaved = false;
              }
              this.savedFiles = allfilesaved;
              this.processedFiles = allfileprocessed;
            } else {
              this.hasFiles = false;
            }
          }
        }).catch(err => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
        }).finally(() => {
          this.loading = false;
        })
      },
      startRun() {
        this.loading = true;
        this.axios
          .post("/v2/bot/analysis/startrun/" + this.botdetails._id)
          .then((ele) => {
            if (ele.data.status === "success") {
              this.$store.commit("sbSuccess", "Run Started");
              this.loadBot();
            } else throw new Error("Error starting run");
          })
          .catch((err) => {
            this.$store.commit("sbError", err.message || err || "Unknown error!");
            console.log(err);
          })
          .finally(() => {
            this.loading = false;
          });
      },
      downloadResult() {
        this.loading = true;
        this.axios.post("/v2/bot/analysis/downloadfile/"+this.botdetails._id).then(dt => {
          if(dt.data.status === "success"){
            let data = dt.data.data[0] || {};
            if(data.stage === 0 || data.stage === 4) this.$store.commit("sbSuccess", "File is being generated please try after sometime");
            else if(data.stage === 2) this.$store.commit("sbSuccess", "There was an error while generating file");
            else if(data.upload) this.$nova.downloadFile(data.upload);
            else return  "no file found to download"
          }else {
            throw new Error (dt.data.message || "Error re-opening project");
          }
        }).catch(err => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        }).finally(() => {
          this.loading = false;
        })
      },
       saveInputParameters() {
         this.loading = true;
        let objectInput = { inputparameter: this.inputparametersvalues };
        this.axios
          .post("/v2/bot/analysis/storeinputparameter/" + this.botdetails._id, {
            data: objectInput,
          })
          .then((ele) => {
            if (ele.data.status === "success") {
              this.$store.commit("sbSuccess", "Input Parameters Updated");
              this.loadBot();
              this.loading = false;
            } else throw new Error("Error updating input parameters");
          })
          .catch((err) => {
            this.$store.commit("sbError", err.message || err || "Unknown error!");
            console.log(err);
          })
          .finally(() => {
             this.loading = false;
          });
      },
    },
    watch: {
    }
  }
  </script>
  